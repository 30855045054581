// Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".
import "phoenix_html"
//import Elm from './elm'
import "leaflet-routing-machine"
//import './geotools2'
//const elmDiv = document.getElementById("elm-main");
//Elm.Main.embed(elmDiv);

//?add port subscription - message from elm 
//var node = document.getElementById('view');

    /* - obsolete
if (elmDiv) {
    var app = Elm.Main.embed(elmDiv);
    // receive something from Elm
    app.ports.toJs.subscribe(function (str) {
        console.log("got from Elm:", str);
    });

//? send a message to elm
    app.ports.toElm.send("hello from JS");
}
export var Talk = {
  run: function(){
    console.log("Hello from app.js function!")
  }
}

*/

//elm = 
// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

// import socket from "./socket"
//
